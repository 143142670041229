<template>
    <div class="component-checkbox-wrapper" :class="checkboxStyle">
        <input :id="id" type="checkbox" :disabled="disabled" :checked="judgeChecked" :value="value" @change="handleChange($event)" />
        <label :for="id" :style="labelStyle"><span class="before" :style="beforeStyle"></span><span class="text" :style="textStyle">{{label ? label : value}}</span><slot></slot></label>
    </div>
</template>

<script>
    /*
        usage:
            single:
                <Checkbox v-model="singleCheck" label="我已阅读并同意" color="red"><span style="color:green; font-weight:bold;">追加的slot</span></Checkbox>
        
            arr:
                <Checkbox v-model="arr" value="大乔" type="custom" color="#f00"></Checkbox>
                <Checkbox v-model="arr" value="小乔" color="#990"></Checkbox>
                <Checkbox v-model="arr" value="凤姐"></Checkbox>
    */ 
    import utils from '../common/utils/utils';

    export default {
        name: "Checkbox",
        model: {
            prop: 'checked',
            event: 'change'
        },
        props: {
            checked: {
                type: Boolean | Array,
                default: false
            },
            value: {
                type: String,
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'primary'
            },
            color: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            customStyle: {
                type: Object,
                default: null
                // default: {
                //     size: '32px', // 框的大小
                //     fs: '16px', // 字体大小
                //     colorDefault: 'gray' // 默认字体颜色
                // }
            }
        },
        data() {
          return {

          }  
        },
        created() {

        },
        computed: {
            id() {
                // return 'id-' + this.$utils.genUUID();
                return 'id-' + utils.genUUID();
            },
            judgeChecked() {
                if(typeof this.checked == 'boolean') {
                    return this.checked;
                } else {
                    return this.checked.includes(this.value);
                }
            },
            checkboxStyle() {
                return 'checkbox--' + this.type;
            },
            labelStyle() {
                let styles = {
                    paddingLeft: '16px'
                };

                if(this.customStyle) {
                    if(this.customStyle.size) {
                        styles.paddingLeft = this.customStyle.size;
                    }
                }
                if(this.disabled) {
                    styles.cursor = 'no-drop';
                }

                return styles;
            },
            beforeStyle() {
                let styles = {};
                if(this.color && this.judgeChecked && !this.disabled) {
                    styles.backgroundColor = this.color;
                    styles.borderColor = this.color;
                    styles.boxShadow = '0 0 2px ' + this.color;
                }
                if(this.customStyle) {
                    if(this.customStyle.size) {
                        styles.width = this.customStyle.size;
                        styles.height = this.customStyle.size;
                    }
                }

                return styles;
            },
            textStyle() {
                let styles = {};
                if(this.color && this.judgeChecked && !this.disabled) {
                    styles.color = this.color;
                }
                if(this.customStyle) {
                    if(this.customStyle.fs) {
                        styles.fontSize = this.customStyle.fs;
                    }
                    if(this.customStyle.colorDefault && !this.judgeChecked && !this.disabled) {
                        styles.color = this.customStyle.colorDefault;
                    }
                }

                return styles;
            }
        },
        methods: {
            handleChange($event) {
                if(typeof this.checked == 'boolean') {
                    this.$emit('change', $event.target.checked)
                } else {
                    if($event.target.checked) {
                        if(!this.checked.includes(this.value)) {
                            this.checked.push(this.value);
                        }
                    } else {
                        if(this.checked.includes(this.value)) {
                            this.checked.splice(this.checked.indexOf(this.value), 1);
                        }
                    }

                    this.$emit('change', this.checked);
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @color-primary: #337ab7;
    @color-primary-dark: #2e6da4;
    @color-success: #5cb85c;
    @color-success-dark: #4cae4c;
    @color-info: #5bc0de;
    @color-info-dark: #46b8da;
    @color-warning: #f0ad4e;
    @color-warning-dark: #eea236;
    @color-danger: #d9534f;
    @color-danger-dark: #d43f3a;

    /* checkbox var */
    @checkboxCheckedColor: @color-primary; // 复选框选中
    @checkboxWidth: 16px;
    /* checkbox var end */

    /* funcs */
    .borderGray(@color: #ccc) {
        border: solid 1px @color;
    }
    .genCheckBoxByColor(@color: @color-primary) {
        input{
            display:none;
            &:checked + label{
                // 选中状态
                .before{
                    .borderGray(@color);
                    box-shadow: 0 0 2px 0 @color;
                    background-color: @color;
                    // border-color: @color;
                    background-image: url("../static/icon-gou.png");
                    background-size: 80%;
                    background-repeat: no-repeat;
                    background-position: center;
                }
                .text{
                    color: @color;
                }
            }
            &:disabled + label{
                // disabled
                .before{
                    .borderGray();
                    box-shadow: 0 0 2px 0 #ccc;
                }
                .text{
                    color: #ccc;
                }
            }
        }
        label{
            cursor: pointer;
            user-select: none;
            position: relative;
            display: inline-block;
            span{
                pointer-events: none;
            }
            // 初始状态
            .before{
                content: '';
                box-sizing: border-box;
                width: @checkboxWidth;
                height: @checkboxWidth;
                background-color: #fff;
                .borderGray();
                border-radius: 2px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
            .text{
                margin-left: 5px;
                font-size: 14px;
                color: #333;
            }
        }
    }
    /* funcs end */

    // 自定义复选框
    .component-checkbox-wrapper{
        display:inline-block;
        -webkit-tap-highlight-color: transparent;
        .genCheckBoxByColor();
        &.checkbox--success{
            .genCheckBoxByColor(@color-success);
        }
        &.checkbox--info{
            .genCheckBoxByColor(@color-info);
        }
        &.checkbox--warning{
            .genCheckBoxByColor(@color-warning);
        }
        &.checkbox--danger{
            .genCheckBoxByColor(@color-danger);
        }
        &.checkbox--custom{
            .genCheckBoxByColor(#FFEB3B)
        }
    }
</style>