<template>
    <div class="component-radio-wrapper" :class="radioStyle">
        <input :id="id" type="radio" :disabled="disabled" :checked="judgeChecked" :value="value" @change="handleChange($event)" />
        <label :for="id" :style="labelStyle"><span class="before" :style="beforeStyle"></span><span class="text" :style="textStyle">{{label ? label : value}}</span><slot></slot></label>
    </div>
</template>

<script>
    /*
    usage:
        <Radio v-model="radioField" label="我已阅读并同意" color="red" value="1"><span style="color:green; font-weight:bold;">追加的slot</span></Radio>
        <Radio v-model="radioField" label="我不同意" value="0"><span style="color:green; font-weight:bold;">追加的slot2</span></Radio>
    */

    export default {
        name: "Radio",
        model: {
            prop: 'checked',
            event: 'change'
        },
        props: {
            name: {
                type: String,
                default: '',
                // required: true
            },
            checked: {
                type: Boolean | String | Number,
                default: false
            },
            value: {
                type: String,
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'primary'
            },
            color: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            customStyle: {
                type: Object,
                default: null
                // default: {
                //     size: '32px', // 框的大小
                //     fs: '16px', // 字体大小
                //     colorDefault: 'gray' // 默认字体颜色
                // }
            }
        },
        data() {
          return {

          }  
        },
        created() {

        },
        computed: {
            colorComputed() {
                if(this.disabled) {
                    return '#ccc';
                }
                if(this.color) {
                    return this.color;
                }
                let colors = {
                    primary: '#337ab7',
                    success: '#5cb85c',
                    info: '#5bc0de',
                    warning: '#f0ad4e',
                    danger: '#d9534f'
                }
                return colors[this.type];
            },
            id() {
                return 'id-' + this.$utils.genUUID();
            },
            judgeChecked() {
                return this.checked == this.value;
            },
            radioStyle() {
                return 'radio--' + this.type;
            },
            labelStyle() {
                let styles = {
                    paddingLeft: '16px'
                };
                if(this.customStyle) {
                    if(this.customStyle.size) {
                        styles.paddingLeft = this.customStyle.size;
                    }
                }
                if(this.disabled) {
                    styles.cursor = 'no-drop';
                }

                return styles;
            },
            beforeStyle() {
                let styles = {};
                let insetShadowWidth = '2px';
                if(this.$utils.getSafeAttr(this.customStyle, 'size')) {
                    insetShadowWidth = 'calc('+ this.customStyle.size +' / 8)';
                }
                if(this.judgeChecked) {
                    styles.borderColor = this.colorComputed;
                    styles.boxShadow = '0 0 4px 0 '+ this.colorComputed +', 0 0 0 '+ insetShadowWidth +' #fff inset';
                    styles.backgroundColor = this.colorComputed;
                }
                if(this.customStyle) {
                    if(this.customStyle.size) {
                        styles.width = this.customStyle.size;
                        styles.height = this.customStyle.size;
                        styles.lineHeight = this.customStyle.size;
                    }
                }

                return styles;
            },
            textStyle() {
                let styles = {};
                if(this.judgeChecked && !this.disabled) {
                    styles.color = this.colorComputed;
                }
                if(this.customStyle) {
                    if(this.customStyle.fs) {
                        styles.fontSize = this.customStyle.fs;
                    }
                    if(this.customStyle.colorDefault && !this.judgeChecked && !this.disabled) {
                        styles.color = this.customStyle.colorDefault;
                    }
                }

                return styles;
            }
        },
        methods: {
            handleChange($event) {
                 this.$emit('change', $event.target.value);
            }
        }
    }
</script>

<style scoped lang="less">
    @color-primary: #337ab7;
    @color-primary-dark: #2e6da4;
    @color-success: #5cb85c;
    @color-success-dark: #4cae4c;
    @color-info: #5bc0de;
    @color-info-dark: #46b8da;
    @color-warning: #f0ad4e;
    @color-warning-dark: #eea236;
    @color-danger: #d9534f;
    @color-danger-dark: #d43f3a;

    .borderGray(@color: #ccc) {
        border: solid 1px @color;
    }
    .genRadioByColor(@color: @color-primary) {
        input{
            display:none;
            &:checked + label{
                // 选中状态
                .before{
                    .borderGray(@color);
                    box-shadow: 0 0 4px 0 @color, 0 0 0 2px #fff inset;
                    background-color: @color;
                }
                .text{
                    color: @color;
                }
            }
            &:disabled + label{
                // disabled
                .before{
                    .borderGray();
                    box-shadow: 0 0 4px 0 #ccc, 0 0 2px 0 #ccc inset;
                }
                .text{
                    color: #ccc;
                }
            }
            &:disabled:checked + label{
                // disabled
                .before{
                    box-shadow: 0 0 4px 0 #ccc, 0 0 0 2px #fff inset;
                    background-color: #ccc;
                }
                .text{
                    color: #ccc;
                }
            }
        }
        label{
            cursor: pointer;
            user-select: none;
            display: inline-block;
            position: relative;
            span{
                pointer-events: none;
            }
            // 初始状态
            .before{
                content: '';
                display: inline-block;
                box-sizing: border-box;
                width: @radioWidth;
                height: @radioWidth;
                line-height: @radioWidth;
                background-color: #fff;
                .borderGray();
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
            .text{
                margin-left: 5px;
                font-size: 14px;
                color: #333;
            }
        }
    }

    /* radio var */
    @radioCheckedColor: @color-primary; // 单选框选中
    @radioWidth: 16px;
    /* radio var end */

    // 自定义单选框
    .component-radio-wrapper{
        display:inline-block;
        -webkit-tap-highlight-color: transparent;
        .genRadioByColor();
        &.radio--success{
            .genRadioByColor(@color-success);
        }
        &.radio--info{
            .genRadioByColor(@color-info);
        }
        &.radio--warning{
            .genRadioByColor(@color-warning);
        }
        &.radio--danger{
            .genRadioByColor(@color-danger);
        }
    }
</style>